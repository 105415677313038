var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('p',{staticClass:"text-sm mb-2",class:[
  {'text-body-dark': !_vm.isFocus && !_vm.errors.has(("" + _vm.name)) && !_vm.hasError && !_vm.disabled},
  {'text-interaction-error': _vm.errors.has(("" + _vm.name)) || _vm.hasError},
  {'text-interaction-primary': _vm.isFocus && (!_vm.errors.has(("" + _vm.name)) && !_vm.hasError)},
  {'text-neutral-500 disabled': _vm.disabled}]},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('quill-editor',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.validate),expression:"validate"}],ref:"quillEditor",class:[
      {'isError': _vm.errors.has(("" + _vm.name)) || _vm.hasError},
      {'isActive': _vm.isFocus && (!_vm.errors.has(("" + _vm.name)) && !_vm.hasError)},
      {'disabled': _vm.disabled} ],attrs:{"readOnly":true,"options":_vm.editorOption,"name":_vm.name},on:{"blur":_vm.tellBlurOccurred,"ready":function($event){return _vm.onEditorReady($event)},"change":function($event){return _vm.onEditorChange($event)},"focus":function($event){return _vm.onEditorFocus($event)}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('div',{staticClass:"flex items-center ancillary mt-2 text-body",attrs:{"id":"counter"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has(("" + _vm.name)) || _vm.hasError),expression:"errors.has(`${name}`) || hasError"}],staticClass:"text-interaction-error"},[_vm._v(_vm._s(_vm.errorMessage || _vm.errors.first(("" + _vm.name))))]),_c('span',{staticClass:"ml-auto",class:[
    {'text-interaction-error': _vm.errors.has(("" + _vm.name)) || _vm.hasError},
    {'text-interaction-primary': _vm.isFocus && (!_vm.errors.has(("" + _vm.name)) && !_vm.hasError)},
    {'text-neutral-500 disabled': _vm.disabled}]},[_vm._v(_vm._s(_vm.textLength)+" "+_vm._s(_vm.counter ? ("/" + _vm.counter) : ''))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }